import * as MdIcon from 'react-icons/md/index' // eslint-disable-line
import * as React from 'react'

import { IconBaseProps } from 'react-icons/lib/iconBase'

export type MaterialIcons =
  | 'MdPersonAdd'
  | 'MdGroup'
  | 'MdCancel'
  | 'MdEmail'
  | 'MdAdd'
  | 'MdArrowForward'
  | 'MdCheck'
  | 'MdAccessTime'
  | 'MdEdit'
  | 'MdDeleteForever'
  | 'MdNote'
  | 'MdCheckBox'
  | 'MdFormatListBulleted'
  | 'MdTimeline'
  | 'MdKeyboardArrowDown'
  | 'MdKeyboardArrowUp'
  | 'MdColorize'
  | string

interface Props extends IconBaseProps {
  icon: MaterialIcons
}

export function MaterialIcon(props: Props) {
  //@ts-ignore
  const Icon = MdIcon[props.icon] // eslint-disable-line

  return <Icon display="block" {...props} />
}
