import * as React from 'react'

import { BaseButton, BaseButtonProps } from './BaseButton'
import { css, cx } from 'emotion'

import { Colors } from '../../helpers/Colors'
import { spacing } from '../../helpers/CssProperties'

interface Props extends BaseButtonProps {}

export function PrimaryButton(props: Props) {
  return (
    <BaseButton
      {...props}
      style={{ backgroundColor: props.color }}
      className={cx([primaryButtonStyle, props.className])}
    >
      {props.children}
    </BaseButton>
  )
}

const primaryButtonStyle = css`
  background-color: ${Colors.cc_primary_100};
  color: ${Colors.cc_white};
  &:active:enabled,
  &:focus:enabled {
    box-shadow: 0 0 ${spacing}rem ${Colors.cc_primary_100};
  }
  > svg {
    fill: ${Colors.cc_secondary_100};
  }
`
