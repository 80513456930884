import * as React from 'react'
import { css } from 'emotion'
import { spacing } from '../../helpers/CssProperties'
import { Colors } from '../../helpers/Colors'
import { Text } from '../Base/Text'

interface Props {
  header: string
  timeInSeconds: number
}

export function SaveNotification(props: Props) {
  const backgroundColor = Colors.cc_success_100
  const color = Colors.cc_white

  return (
    <div
      className={css`
        padding: ${spacing * 1}rem ${spacing * 2}rem;
        margin: ${spacing * 2}rem;
        position: relative;
        background-color: ${backgroundColor};
        color: ${color};
        /* box-shadow: 0 0 ${spacing * 2}rem 0 rgba(0, 0, 0, 0.2); */
      `}
    >
      <Text>{props.header}</Text>
    </div>
  )
}
