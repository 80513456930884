import * as React from 'react'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

// import { CreateNewUserPage } from './pages/createNewUser/CreateNewUserPage'
// import { ForgotPage } from 'src/apps/login/pages/forgot/ForgotPage'
import { LoginPage } from 'src/apps/login/pages/login/LoginPage'
// import { ResetPage } from 'src/apps/login/pages/reset/ResetPage'

// FIXME: disable all but /
export default function App() {
  return (
    <Router basename="login">
      <Routes>
        <Route path="/" element={<LoginPage />} />
      </Routes>
    </Router>
  )
}
// <Route path="/forgot" element={<ForgotPage />} />
// <Route path="/forgotPassword/:token" element={<ResetPage />} />
// <Route
//   path="/initiative/:id/createNewUser/:jwt"
//   element={<CreateNewUserPage />}
// />
