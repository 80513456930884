import * as React from 'react'
import { spacing } from '../../helpers/CssProperties'

interface VerticalSpacingProps {
  amount?: number
}

export function VerticalSpacing(props: VerticalSpacingProps) {
  return (
    <div
      style={{
        flex: '0 0 !important',
        height: props.amount ? `${props.amount * spacing}rem` : `${spacing}rem`
      }}
    />
  )
}
VerticalSpacing.displayName = 'VerticalSpacing'
