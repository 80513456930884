import * as React from 'react'
import { Modal, ModalProps } from '../Modals/Modal'
import { css } from 'emotion'
import { Text } from '../Base/Text'
import { spacing } from '../../helpers/CssProperties'
import { Colors } from '../../helpers/Colors'
import { PrimaryButton } from '../Buttons/PrimaryButton'

interface Props extends ModalProps {
  type: 'success' | 'warning' | 'error'
}

export function StandardNotificationModal(props: Props) {
  let backgroundColor = Colors.cc_white
  let accentColor = Colors.cc_success_085
  let textColor = Colors.cc_black

  if (props.type === 'error') {
    accentColor = Colors.cc_error_100
    textColor = Colors.cc_error_100
  } else if (props.type === 'warning') {
    accentColor = Colors.cc_warning_085
  }

  return (
    <Modal
      open={props.open}
      title={props.title}
      closeOnBackdropClick={props.closeOnBackdropClick}
      closeWithEscape={props.closeWithEscape}
      onCloseRequest={props.onCloseRequest}
      backgroundColor={backgroundColor}
      borderLeftColor={accentColor}
    >
      <div
        className={css`
          color: ${textColor};
        `}
      >
        <Text as="p">{props.children}</Text>
      </div>
      <div
        className={css`
          float: right;
          margin-top: ${spacing * 2}rem;
        `}
      >
        <PrimaryButton onClick={props.onCloseRequest}>OK</PrimaryButton>
      </div>
    </Modal>
  )
}
