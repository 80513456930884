import * as React from 'react'
import { useTransition, animated } from 'react-spring'
import { useNotificationCenter } from '../../hooks/useNotificationCenter'
import { css } from 'emotion'
import { useForceRender } from '../../hooks/useForceRender'
import { spacing, remToPixels } from '../../helpers/CssProperties'

export function NotificationContainer() {
  const notificationCenter = useNotificationCenter()
  const forceRender = useForceRender()
  const ref = React.useRef(null)
  React.useEffect(() => {
    function onToastsChanged() {
      notificationCenter.toasts
      forceRender()
    }

    notificationCenter.subscribeToToasts(onToastsChanged)

    return () => {
      notificationCenter.unsubscribeToToasts(onToastsChanged)
    }
  }, [notificationCenter, forceRender])
  const margin = spacing * 2 * remToPixels
  const width = 350
  // const height = 80
  const transitions = useTransition(
    notificationCenter.toasts.map(data => {
      const result = { ...data, height: data.height }
      return result
    }),
    (toast: any) => toast.id, // eslint-disable-line
    {
      from: {
        opacity: 0,
        height: 0
      },
      //@ts-ignore
      enter: ({ height }) => ({
        opacity: 1,
        height: height + margin
      }),
      leave: {
        opacity: 0,
        height: 0
      }
    }
  )
  return (
    <>
      <div>
        {/* eslint-disable-next-line */}
        {notificationCenter.alerts.map((alert: any) => {
          return <div key={alert.key}>{alert.component}</div>
        })}
      </div>

      <div
        className={css`
          position: fixed;
          top: 0;
          right: 0;
          width: ${width}px;
          z-index: 100;
        `}
      >
        {/* eslint-disable-next-line */}
        {transitions.map((transition: any) => {
          return (
            <animated.div
              ref={ref}
              key={transition.key}
              style={{
                ...transition.props,
                position: 'relative'
              }}
            >
              <div style={{ position: 'absolute', right: 0, top: '48px' }}>
                {transition.item.component}
              </div>
            </animated.div>
          )
        })}
      </div>
    </>
  )
}
