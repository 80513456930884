import React, { useState } from 'react'
import { spacing, transitions } from 'src/apps/main/helpers/CssProperties'

import { Colors } from 'src/apps/main/helpers/Colors'
import { Text } from 'src/apps/main/components/Base/Text'
import { css } from 'emotion'
import { isOnMobile } from '../../helpers/navigator'

interface Props {
  text?: string
  hotKeyText?: string /** for hotkey icons, only to use with props.text */
  tooltipContent?: React.ReactNode /** when tooltip content needs to be a element, not string */
  children: React.ReactNode
  align?:
    | 'center'
    | 'left'
    | 'right' /** alignment relative to wrapped element */
  alignLeftPosition?: number
  bottom?: boolean /** positioned below wrapped element */
}

export const Tooltip = (props: Props) => {
  if (!props.tooltipContent && !props.text) {
    throw Error('You need to set either text or tooltipContent')
  }
  const [isVisible, setIsVisible] = useState(false)
  const showTooltip = () => {
    setIsVisible(!isOnMobile())
  }
  const hideTooltip = () => {
    setIsVisible(false)
  }
  let transition = isVisible
    ? transitions('opacity', 'hover') + '.2s'
    : transitions('opacity', 'hover')
  let alignTooltip = `left: ${
    props.alignLeftPosition || 50
  }%; transform: translate(-50%, 0);`
  let alignArrow = 'left: 50%; transform: translate(-50%, 0);'

  if (props.align === 'right') {
    alignTooltip = 'right: 0; left: auto; transform: translate(0, 0);'
    alignArrow = `left: auto; right: 0; transform: translate(${-spacing}rem, 0);`
  } else if (props.align === 'left') {
    alignTooltip = 'left: 0; transform: translate(0, 0);'
    alignArrow = `left: 0; transform: translate(${spacing}rem , 0);`
  }

  return (
    <div className={WrapperStyle}>
      <span
        className={TextWrapperStyle(
          props,
          alignTooltip,
          transition,
          isVisible,
          alignArrow
        )}
      >
        {props.tooltipContent && props.tooltipContent}
        {props.text && (
          <Text as="p" pType="pSmall" whiteSpace="nowrap">
            {props.text}
            {props.hotKeyText && (
              <span className={HotKeyTextStyle}>{props.hotKeyText}</span>
            )}
          </Text>
        )}
      </span>
      <span onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {props.children}
      </span>
    </div>
  )
}

const WrapperStyle = css`
  position: relative;
`

const TextWrapperStyle = (
  props: Props,
  alignTooltip: string,
  transition: string,
  isVisible: boolean,
  alignArrow: string
) => css`
  ${alignTooltip};
  position: absolute;
  display: block;
  top: ${props.bottom ? '120%' : undefined};
  bottom: ${props.bottom ? undefined : '120%'};
  opacity: ${isVisible ? 1 : 0};
  background-color: ${Colors.cc_primary_100};
  color: ${Colors.cc_white};
  padding: ${spacing * 0.5}rem ${spacing}rem;
  transition: ${transition};
  z-index: 1000;
  pointer-events: none;
  :after {
    ${alignArrow}
    position: absolute;
    content: '';
    display: block;
    height: 0;
    width: 0;
    top: ${props.bottom ? '-4px' : undefined};
    bottom: ${props.bottom ? undefined : '-4px'};
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: ${props.bottom
      ? undefined
      : `5px solid ${Colors.cc_primary_100}`};
    border-bottom: ${props.bottom
      ? `5px solid ${Colors.cc_primary_100}`
      : undefined};
    line-height: 0;
    font-size: 0;
  }
`

const HotKeyTextStyle = css`
  opacity: 0.5;
  margin-left: ${spacing}rem;
`
