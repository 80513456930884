export const Colors = {
  //Match with colors from stylesheet
  cc_grey_050: 'rgb(251,251,251)',
  cc_grey_100: 'rgb(244,244,244)',
  cc_grey_200: 'rgb(235,235,235)',
  cc_grey_300: 'rgb(217,217,217)',
  cc_grey_400: 'rgb(210,210,210)',
  cc_grey_500: 'rgb(184,184,184)',
  cc_grey_500_hex: '#b8b8b8',
  cc_grey_500_transparent_03: 'rgba(184,184,184, 0.3)',
  cc_grey_600: 'rgb(148,148,148)',
  cc_grey_700: 'rgb(75,85,99)',
  cc_grey_700_hex: '#4b5563',
  cc_black: 'rgb(0,0,0)',
  cc_white: 'rgb(255,255,255)',
  cc_primary_100: 'rgb(34,34,34)',
  cc_primary_085: 'rgb(68,68,68)',
  cc_primary_075: 'rgb(101,101,101)',
  cc_primary_050: 'rgb(134,134,134)',
  cc_secondary_darker: '#f7d400',
  cc_secondary_100: 'rgb(255,219,0)',
  cc_secondary_085: 'rgb(255,224,38)',
  cc_secondary_085_transparent_85: 'rgb(255,224,38,0.85)',
  cc_secondary_050: 'rgb(255,237,128)',
  cc_secondary_050_transparent_08: 'rgb(255,237,128,0.8)',
  cc_secondary_020: 'rgb(255,248,204)',
  cc_tertiary_100: 'rgb(0,91,175)',
  cc_tertiary_085: 'rgb(26,104,176)',
  cc_tertiary_050: 'rgb(78, 133,186)',
  cc_tertiary_020: 'rgb(151,177,211)',

  cc_light_blue: 'rgb(100 185 255)',

  cc_error_100: 'rgb(195,55,52)',
  cc_error_100_hex: '#c33734',
  cc_error_085: 'rgb(205,88,85)',
  cc_error_060: 'rgb(229,167,166)',
  cc_error_020: 'rgb(247,226,225)',
  cc_error_Transparent_085: 'rgba(195,55,52,0.85)',
  cc_error_Transparent_050: 'rgba(195,55,52,0.50)',
  cc_error_Transparent_025: 'rgba(195,55,52,0.25)',

  cc_warning_100: 'rgb(255,161,0)',
  cc_warning_100_hex: '#ffa100',
  cc_warning_085: 'rgb(255,184,62)',
  cc_warning_060: 'rgb(255,214,142)',
  cc_warning_060_hex: '#ffd68e',
  cc_warning_020: 'rgb(255,237,204)',
  cc_warning_Transparent_085: 'rgba(255,161,0,0.85)',
  cc_warning_Transparent_050: 'rgba(255,161,0,0.5)',
  cc_warning_Transparent_025: 'rgba(255,161,0,0.25)',

  cc_success_100: 'rgb(0,144,80)',
  cc_success_100_hex: '#009050',
  cc_success_085: 'rgb(45,164,111)',
  cc_success_060: 'rgb(142,206,178)',
  cc_success_020: 'rgb(204,233,220)',

  blackTransparent: 'rgba(34,34,34,0.8)',
  cardOutline: 'rgb(240,240,240)',
  cc_timeline: 'rgb(10,117,226)',
  cc_deliverables_card: 'rgb(228,239,245)'
}

export type PhaseStateColors =
  | 'cc_grey_500'
  | 'cc_grey_500_hex'
  | 'cc_grey_700'
  | 'cc_grey_700_hex'
  | 'cc_error_100'
  | 'cc_error_100_hex'
  | 'cc_warning_100'
  | 'cc_warning_100_hex'
  | 'cc_warning_060'
  | 'cc_warning_060_hex'
  | 'cc_success_100'
  | 'cc_success_100_hex'

export const defaultParkedColor = `repeating-linear-gradient(
  -45deg,
  ${Colors.cc_grey_200},
  ${Colors.cc_grey_200} 20px,
  ${Colors.cc_white} 20px,
  ${Colors.cc_white} 40px
)`

export const DefaultClusterColor = {
  name: 'GREY 50',
  hsl: '0,0%,72%'
}

export interface Color {
  name: string
  hsl: string
}

export const IkeaColors: Color[] = [
  {
    name: 'PANTONE 12-2903 TCX',
    hsl: '316,40%,87%'
  },
  {
    name: 'IKEA PINK no 7',
    hsl: '352,84%,85%'
  },
  {
    name: 'PANTONE 12-0804 TCX',
    hsl: '50,37%,84%'
  },
  {
    name: 'IKEA YELLOW no 29',
    hsl: '46,63%,78%'
  },
  {
    name: 'IKEA BEIGE no 4 ',
    hsl: '39,42%,77%'
  },
  {
    name: 'PANTONE 14-1318 TCX',
    hsl: '10,71%,77%'
  },
  {
    name: 'PANTONE 13-4200 TCX',
    hsl: '197,43%,77%'
  },
  {
    name: 'IKEA BEIGE 1 ',
    hsl: '42,18%,75%'
  },
  {
    name: 'PANTONE 15-2216 TCX',
    hsl: '342,65%,75%'
  },
  {
    name: 'IKEA GREY 30 ',
    hsl: '40,2%,73%'
  },
  {
    name: 'PAX FY17 Light Turq no1',
    hsl: '178,24%,73%'
  },
  {
    name: 'IKEA BLUE no 58',
    hsl: '198,50%,71%'
  },
  {
    name: 'IKEA YELLOW no 28',
    hsl: '46,83%,70%'
  },
  {
    name: 'IKEA BEIGE 2 ',
    hsl: '38,15%,65%'
  },
  {
    name: 'PANTONE 18-1649 TCX',
    hsl: '1,73%,65%'
  },
  {
    name: 'PANTONE 16-3521 TCX',
    hsl: '312,23%,65%'
  },
  {
    name: 'PANTONE 15-6315 TCX',
    hsl: '77,12%,62%'
  },
  {
    name: 'PANTONE 13-0442 TCX',
    hsl: '54,39%,62%'
  },
  {
    name: 'IKEA PINK no 5',
    hsl: '340,63%,61%'
  },
  {
    name: 'PANTONE 13-0532 TCX',
    hsl: '61,33%,61%'
  },
  {
    name: 'PANTONE 16-0940 TCX',
    hsl: '31,36%,60%'
  },
  {
    name: 'IKEA BEIGE 3  ',
    hsl: '27,10%,58%'
  },
  {
    name: 'PANTONE 15-5218 TCX',
    hsl: '172,33%,57%'
  },
  {
    name: 'IKEA ORANGE no 10',
    hsl: '7,81%,56%'
  },
  {
    name: 'PANTONE 15-4323 TCX',
    hsl: '196,63%,54%'
  },
  {
    name: 'PANTONE 15-6428 TCX',
    hsl: '81,21%,52%'
  },
  {
    name: 'PANTONE 15-0850 TCS',
    hsl: '42,65%,51%'
  },
  {
    name: 'IKEA GREEN no 63',
    hsl: '42,24%,51%'
  },
  {
    name: 'IKEA YELLOW no 24',
    hsl: '49,100%,50%'
  },
  {
    name: 'PANTONE 16-0639 TCX',
    hsl: '48,39%,49%'
  },
  {
    name: 'IKEA YELLOW no 31',
    hsl: '44,100%,48%'
  },
  {
    name: 'PANTONE 16-5412 TCX',
    hsl: '175,30%,47%'
  },
  {
    name: 'PANTONE 16-1454 TCX',
    hsl: '23,100%,45%'
  },
  {
    name: 'PANTONE 18-1550 TCX',
    hsl: '5,71%,45%'
  },
  {
    name: 'PANTONE 17-0123 TCX',
    hsl: '104,18%,45%'
  },
  {
    name: 'PANTONE 17-4123 TCX',
    hsl: '197,52%,43%'
  },
  {
    name: 'IKEA RED 31',
    hsl: '357,77%,42%'
  },
  {
    name: 'PANTONE 17-0330 TCX',
    hsl: '68,27%,42%'
  }
]
