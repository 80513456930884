import { css } from 'emotion'

export const remToPixels = 12 // 55 comes from style.css html { font-size: 55% }

export const spacing = 8 / 12
export const headerHeight = 4
export const breadCrumbHeaderHeight = 3
export const mobileBreadCrumbHeaderHeight = 4
export const defaultIconSize = 16

export const transitionDuration = {
  express: 0.01,
  fast: 0.125,
  default: 0.2,
  medium: 0.3,
  slow: 0.5
}

const customCubicBezier = [0.465, 0.183, 0.153, 0.946]

export const motionTransitions = {
  expand: {
    duration: 0.3,
    ease: customCubicBezier
  }
}

export const transitionTiming = {
  default: 'ease-in-out',
  simple: 'ease',
  custom: `cubic-bezier(${customCubicBezier.toString()})`
}

type transitionType =
  | 'hover'
  | 'backgroundChange'
  | 'horizontalIn'
  | 'horizontalOut'
  | 'verticalExpand'
  | 'verticalCollapse'
  | 'flip'
  | 'tooltip'

const transitionShorthands: { [key in transitionType]: string } = {
  hover: `${transitionDuration.default}s ${transitionTiming.default}`,
  backgroundChange: `${transitionDuration.fast}s ${transitionTiming.default}`,
  horizontalIn: `${transitionDuration.medium}s ${transitionTiming.custom}`,
  horizontalOut: `${transitionDuration.fast}s ${transitionTiming.simple}`,
  verticalExpand: `${transitionDuration.medium}s ${transitionTiming.custom}`,
  verticalCollapse: `${transitionDuration.express}s ${transitionTiming.simple}`,
  flip: `${transitionDuration.slow}s ${transitionTiming.custom}`,
  tooltip: `${transitionDuration.default}s ${transitionTiming.default}`
}

export const transitions = (
  cssProperty: string,
  transitionType: transitionType
) => {
  return `${cssProperty} ${transitionShorthands[transitionType]}`
}

export const sidebarWidth = {
  desktop: {
    collapsed: '58px',
    expanded: '220px'
  },
  handheld: {
    expanded: '100%'
  }
}

export const fontSizes = {
  small: 10 / 12 + 'rem',
  medium: 12 / 12 + 'rem',
  default: 14 / 12 + 'rem',
  large: 16 / 12 + 'rem',
  extraLarge: 24 / 12 + 'rem',
  statsNumber: '1rem'
}

export const lineHeight = {
  small: 14 / 12 + 'rem',
  medium: 16 / 12 + 'rem',
  default: 24 / 12 + 'rem',
  large: 24 / 12 + 'rem',
  extraLarge: 32 / 12 + 'rem',
  statsNumber: 36 / 12 + 'rem'
}

export const fontFamily = 'Noto IKEA Latin'
export const fontFamilyBold = 'Noto IKEA Latin bold'
export const fontWeightNormal = 400
export const fontWeightBold = 700
export const fontStyles = {
  h1: {
    fontSize: fontSizes.extraLarge,
    fontWeight: fontWeightNormal,
    lineHeight: lineHeight.extraLarge
  },
  h2: {
    fontSize: fontSizes.large,
    fontWeight: fontWeightBold,
    lineHeight: lineHeight.large
  },
  h3: {
    fontSize: fontSizes.medium,
    fontWeight: fontWeightBold,
    lineHeight: lineHeight.extraLarge
  },
  h4: {
    fontSize: fontSizes.medium,
    fontWeight: fontWeightBold,
    lineHeight: lineHeight.medium
  },
  p: {
    fontSize: fontSizes.medium,
    fontWeight: fontWeightNormal,
    lineHeight: lineHeight.medium
  },
  pLarge: {
    fontSize: fontSizes.large,
    fontWeight: fontWeightNormal,
    lineHeight: lineHeight.default
  },
  pSmall: {
    fontSize: fontSizes.small,
    fontWeight: fontWeightNormal,
    lineHeight: lineHeight.small
  },
  price: {
    fontSize: '3.2rem',
    fontWeight: fontWeightBold,
    lineHeight: '1.5'
  },
  mega: {
    fontSize: fontSizes.extraLarge,
    fontWeight: fontWeightBold,
    lineHeight: lineHeight.statsNumber
  }
}

export const zIndex = {
  layer1: 10,
  layer2: 20,
  layer3: 30,
  layer4: 40,
  layer5: 50,
  sideMenu: 60,
  header: 65,
  modalBackdrop: 70,
  modal: 80
}
export const inputFieldSize = '28rem'

export const screenSize = {
  small: '576px',
  medium: '896px',
  large: '1224px',
  xlarge: '1824px'
}

export const IdeaSelectCheckboxClass = (selected: boolean) => css`
  transition: opacity 0.2s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: ${selected ? 1 : 0};
  cursor: pointer;
`
