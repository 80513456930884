import * as React from 'react'
import { guid } from '../helpers/uuid'
import { StandardNotificationModal } from '../components/Notifications/StandardNotificationModal'
import { StandardToastNotification } from '../components/Notifications/StandardToastNotification'
import { SaveNotification } from '../components/Notifications/SaveNotification'

interface Toast {
  id: string
  component: React.ReactElement
  height: number
}

interface Alert {
  id: string
  component: React.ReactElement
}
export class NotificationCenter {
  mountNode: HTMLDivElement

  toasts: Toast[] = []
  alerts: Alert[] = []
  private toastListeners: (() => void)[] = []

  constructor() {
    this.mountNode = document.createElement('div')
    document.body.appendChild(this.mountNode)
  }

  // eslint-disable-next-line
  alert(component: React.ReactComponentElement<any, any>) {
    const id = guid()

    const clone = React.cloneElement(component, {
      id: id
    })

    this.alerts.push({
      id,
      component: clone
    })

    this.notifyToastSubscribers()
  }

  alertStandardError(title: string, message: string) {
    const component = (
      <StandardNotificationModal
        open
        title={title}
        closeOnBackdropClick={false}
        closeWithEscape
        onCloseRequest={() => this.closeModalOnTop()}
        type="error"
      >
        {message}
      </StandardNotificationModal>
    )

    this.alert(component)
  }

  alertStandardWarning(title: string, message: string) {
    const component = (
      <StandardNotificationModal
        open
        title={title}
        closeOnBackdropClick={false}
        closeWithEscape
        onCloseRequest={() => this.closeModalOnTop()}
        type="warning"
      >
        {message}
      </StandardNotificationModal>
    )

    this.alert(component)
  }

  alertStandardSuccess(title: string, message: string) {
    const component = (
      <StandardNotificationModal
        open
        title={title}
        closeOnBackdropClick={false}
        closeWithEscape
        onCloseRequest={() => this.closeModalOnTop()}
        type="success"
      >
        {message}
      </StandardNotificationModal>
    )

    this.alert(component)
  }

  // eslint-disable-next-line
  toast(component: React.ReactComponentElement<any, any>, height: number) {
    const id = guid()

    const clone = React.cloneElement(component, {
      id: id
    })

    this.toasts.push({
      id,
      component: clone,
      height: height
    })
    if (component.props.timeInSeconds) {
      this.startTimer(id, component.props.timeInSeconds)
    }

    this.notifyToastSubscribers()
  }

  toastStandardError(title: string, message: string, timeInSeconds?: number) {
    const component = (
      <StandardToastNotification
        header={title}
        type="error"
        timeInSeconds={timeInSeconds}
      >
        {message}
      </StandardToastNotification>
    )

    this.toast(component, 80)
  }

  toastStandardWarning(title: string, message: string, timeInSeconds?: number) {
    const component = (
      <StandardToastNotification
        header={title}
        type="warning"
        timeInSeconds={timeInSeconds}
      >
        {message}
      </StandardToastNotification>
    )

    this.toast(component, 80)
  }

  toastStandardSuccess(title: string, message: string, timeInSeconds?: number) {
    const component = (
      <StandardToastNotification
        header={title}
        type="success"
        timeInSeconds={timeInSeconds}
      >
        {message}
      </StandardToastNotification>
    )
    this.toast(component, 80)
  }
  saveNotification() {
    const component = (
      <SaveNotification header={'Saved'} timeInSeconds={1}></SaveNotification>
    )
    this.toast(component, 40)
  }

  notifyToastSubscribers() {
    for (let i = 0; i < this.toastListeners.length; i++) {
      this.toastListeners[i]()
    }
  }

  subscribeToToasts(listener: () => void) {
    this.toastListeners.push(listener)
  }

  unsubscribeToToasts(listener: () => void) {
    this.toastListeners = this.toastListeners.filter(lis => lis !== listener)
  }

  startTimer(id: string, time: number) {
    setTimeout(() => {
      this.removeToast(id)
    }, time * 1000)
  }

  removeToast(id: string) {
    for (let i = 0; i < this.toasts.length; i++) {
      const toast = this.toasts[i]
      if (toast.id === id) {
        this.toasts.splice(i, 1)
        this.notifyToastSubscribers()
        return
      }
    }
  }

  closeModalOnTop() {
    this.alerts.pop()

    this.notifyToastSubscribers()
  }
}
