import * as React from 'react'
import { css } from 'emotion'
import { MaterialIcon } from '../Base/MaterialIcon'
import { spacing } from '../../helpers/CssProperties'
import { useNotificationCenter } from '../../hooks/useNotificationCenter'
import { Colors } from '../../helpers/Colors'
import { Text } from '../Base/Text'

interface Props {
  id?: string
  type: 'error' | 'success' | 'warning'
  header: string
  children: React.ReactNode
  timeInSeconds?: number
}

export function StandardToastNotification(props: Props) {
  const notificationCenter = useNotificationCenter()

  // Default to success styling
  let backgroundColor = Colors.cc_success_100
  let borderColor = Colors.cc_success_085
  let color = Colors.cc_white
  if (props.type === 'error') {
    borderColor = Colors.cc_error_085
    backgroundColor = Colors.cc_error_100
    color = Colors.cc_white
  } else if (props.type === 'warning') {
    borderColor = Colors.cc_warning_085
    backgroundColor = Colors.cc_warning_100
    color = Colors.cc_white
  }
  return (
    <div
      onClick={() => {
        notificationCenter.removeToast(props.id!)
      }}
      className={css`
        padding: ${spacing * 2}rem;
        margin: ${spacing * 2}rem;
        position: relative;
        background-color: ${backgroundColor};
        color: ${color};
        box-shadow: 0 0 ${spacing * 2}rem 0 rgba(${Colors.cc_grey_500} 0.2);
      `}
    >
      <div
        className={css`
          position: absolute;
          top: ${spacing * 0.5}rem;
          right: ${spacing * 0.5}rem;
          height: ${spacing * 2}rem;
          width: ${spacing * 2}rem;
          display: flex;
          align-items: center;
          justify-content: center;
          :hover {
            cursor: pointer;
            opacity: 0.5;
          }
        `}
      >
        <MaterialIcon
          className={css`
            height: ${spacing * 2}rem;
            width: ${spacing * 2}rem;
            color: ${color};
          `}
          icon="MdClear"
        />
      </div>
      <div
        className={css`
          margin-right: ${spacing}rem;
        `}
      >
        <Text as="h4">{props.header}</Text>

        <Text as="p">{props.children}</Text>
      </div>

      {props.timeInSeconds && (
        <div
          className={css`
            position: relative;
            padding: ${spacing}rem;
          `}
        >
          <div
            className={css`
              @keyframes progress-bar {
                100% {
                  width: 100%;
                }
              }
              position: absolute;
              bottom: 0;
              left: 0;
              height: ${spacing * 0.5}rem;
              background-color: ${borderColor};
              animation: progress-bar ${props.timeInSeconds}s linear;
              width: 0;
            `}
          ></div>
        </div>
      )}
    </div>
  )
}
