// import 'whatwg-fetch'

// import {
//   hasMinLength,
//   useFormValidation,
//   validateIsEmail
// } from 'src/apps/main/hooks/useFormValidation'

import { Colors } from 'src/apps/main/helpers/Colors'
// import { InputField } from 'src/apps/main/components/Form/InputField'
// import { InputGroup } from 'src/apps/main/components/Form/InputGroup'
// import { Link } from 'react-router-dom'
// import { LinkText } from 'src/apps/main/components/Links/LinkText'
import { PrimaryButton } from 'src/apps/main/components/Buttons/PrimaryButton'
import React from 'react'
import { Text } from 'src/apps/main/components/Base/Text'
import { VerticalSpacing } from 'src/apps/main/components/layout/VerticalSpacing'
import { loginDocumentTitle } from '../../helpers/documentTitles'
import logo from 'src/apps/main/assets/ikea-new-logo-dnp.svg'
import { spacing } from 'src/apps/main/helpers/CssProperties'
// import { useNotificationCenter } from 'src/apps/main/hooks/useNotificationCenter'

const singleSignOnUrl = '/api/user/sso-login'

// FIXME: disable external user
export function LoginPage() {
  // const notificationCenter = useNotificationCenter()
  // const [externalUser, setExternalUser] = React.useState(false) // Toggle between single sign on or external (username/password) login
  // const [formData, setFormData] = React.useState({
  //   username: '',
  //   password: ''
  // })

  // const [formErrors, validateForm] = useFormValidation(formData, {
  //   username: [validateIsEmail],
  //   password: [hasMinLength(1)]
  // })

  React.useEffect(() => {
    document.title = loginDocumentTitle
  })

  // function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
  //   event.preventDefault()
  //   if (!validateForm()) {
  //     return
  //   }
  //   window
  //     .fetch(`/api/user/login${window.location.search}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         user: formData.username,
  //         password: formData.password
  //       })
  //     })
  //     .then(res => {
  //       if (res.status === 401) {
  //         return Promise.reject(401)
  //       }
  //       return res.json()
  //     })
  //     .then(res => {
  //       if (/Edge/.test(navigator.userAgent)) {
  //         // FIXME: this token should expire way sooner,
  //         //        as well as change every time the user logs in
  //         // Fix for Edge not creating cookies properly from response headers
  //         var expires = new Date()
  //         expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000)
  //         document.cookie = `auth_token=${
  //           res.token
  //         };path=/;expires=${expires.toUTCString()}`
  //       }

  //       if (res.redirected) {
  //         window.location.href = res.url
  //       }
  //     })
  //     .catch(err => {
  //       if (err === 401) {
  //         notificationCenter.toastStandardError(
  //           'Login error',
  //           'Email and/or password is incorrect'
  //         )
  //       } else {
  //         notificationCenter.toastStandardError(
  //           'Failed to login',
  //           'Please try again',
  //           3
  //         )
  //       }
  //     })
  // }

  return (
    <div style={{ padding: '2.4rem' }}>
      <div style={{ height: '10vh' }} />
      <div
        style={{
          maxWidth: '55rem',
          margin: '0 auto',
          padding: `${spacing * 2}rem`,
          backgroundColor: Colors.cc_white
        }}
      >
        <VerticalSpacing amount={2} />
        <div style={{ textAlign: 'center' }}>
          <img src={logo} width={100} />
        </div>
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Text as="h3">Welcome to 10 jobs development tool</Text>

            <PrimaryButton
              onClick={() => {
                window.location.href = `${singleSignOnUrl}${window.location.search}`
              }}
            >
              Log in
            </PrimaryButton>
          </div>

          {
            // <div
            //   data-testid="externalUserLink"
            //   style={{
            //     paddingTop: `${spacing * 2}rem`,
            //     textAlign: 'end'
            //   }}
            //   role="button"
            //   onClick={() => setExternalUser(true)}
            // >
            //   <LinkText>External user</LinkText>
            // </div>
          }
        </>
        {
          // externalUser && (
          //   <form onSubmit={handleSubmit}>
          //     <InputGroup
          //       label="Email"
          //       error={!!formErrors.username}
          //       errorText={formErrors.username}
          //     >
          //       <InputField
          //         testId="loginEmail"
          //         key={'email'}
          //         inputType="username"
          //         autoFocus
          //         error={!!formErrors.username}
          //         value={formData.username}
          //         onChange={username =>
          //           setFormData({
          //             ...formData,
          //             username
          //           })
          //         }
          //       />
          //     </InputGroup>

          //     <InputGroup
          //       label="Password"
          //       error={!!formErrors.password}
          //       errorText={formErrors.password}
          //     >
          //       <InputField
          //         testId="loginPassword"
          //         key={'password'}
          //         inputType="password"
          //         autoComplete="current-password"
          //         error={!!formErrors.password}
          //         value={formData.password}
          //         onChange={password =>
          //           setFormData({
          //             ...formData,
          //             password
          //           })
          //         }
          //       />
          //     </InputGroup>
          //     <div
          //       style={{
          //         display: 'flex',
          //         justifyContent: 'space-between',
          //         alignItems: 'center'
          //       }}
          //     >
          //       <Link to="/forgot">
          //         <LinkText>Forgot password?</LinkText>
          //       </Link>
          //       <PrimaryButton testId={'externalLoginButton'} type="submit">
          //         Log in
          //       </PrimaryButton>
          //     </div>
          //   </form>
          // )
        }
      </div>
    </div>
  )
}
