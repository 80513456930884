import * as React from 'react'
import * as ReactDOM from 'react-dom'
import MdIcons from 'react-icons/md' // eslint-disable-line
import { Backdrop } from './Backdrop'
import { Colors } from 'src/apps/main/helpers/Colors'
import {
  spacing,
  zIndex,
  screenSize,
  remToPixels
} from 'src/apps/main/helpers/CssProperties'
import { css } from 'emotion'
import { Text } from '../Base/Text'

export interface ModalProps {
  open: boolean
  title?: string
  closeOnBackdropClick: boolean
  closeWithEscape: boolean
  roundCloseButton?: boolean
  width?: number
  maxWidth?: string
  maxHeight?: boolean
  fullscreen?: boolean
  onCloseRequest: () => void
  onSubmit?: (e: Event) => void
  children: React.ReactNode
  backgroundColor?: string
  borderLeftColor?: string
  isFlexStart?: boolean
}

export class Modal extends React.Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props)
  }

  componentDidMount() {
    // Trigger the modal's defined onBackdropClick on Esc press

    document.addEventListener('keydown', this.closeOnEscape)
    document.addEventListener('keydown', this.submitOnEnter)
  }

  componentDidUpdate() {
    // remove scroll from body if modal is open
    if (this.props.open) {
      document.body.classList.add('no-scroll')
    }
    if (!this.props.open) {
      document.body.classList.remove('no-scroll')
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeOnEscape)
    document.removeEventListener('keydown', this.submitOnEnter)
  }

  closeOnEscape = (e: KeyboardEvent) => {
    if (!this.props.open) {
      return
    }
    if (e.keyCode === 27 && this.props.closeWithEscape) {
      this.props.onCloseRequest()
    }
  }

  submitOnEnter = (e: KeyboardEvent) => {
    if (!this.props.open) {
      return
    }
    if (e.keyCode === 13 && this.props.onSubmit) {
      e.preventDefault()
      this.props.onSubmit(e)
    }
  }

  render() {
    if (!this.props.open) {
      return null
    }
    return ReactDOM.createPortal(
      <Backdrop
        isFlexStart={this.props.isFlexStart}
        open={this.props.open}
        onBackdropClick={() => {
          if (this.props.closeOnBackdropClick && this.props.onCloseRequest) {
            this.props.onCloseRequest()
          }
        }}
      >
        {this.props.fullscreen ? (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              zIndex: zIndex.modal,
              overflow: 'auto',
              background: Colors.cc_white
            }}
          >
            <div>{this.props.children}</div>
          </div>
        ) : (
          <div
            className={css`
              position: relative;
              width: ${this.props.width || '90%'};
              max-height: ${this.props.maxHeight ? '90vh' : 'undefined'};
              @media only screen and (min-width: ${screenSize.small}) {
                max-width: ${this.props.maxWidth
                  ? this.props.maxWidth
                  : '55rem'};
                width: calc(100% - ${spacing * 10}rem);
              }
            `}
          >
            <div
              className={css({
                maxHeight: this.props.maxHeight ? '90vh' : 'undefined',
                overflowY: this.props.maxHeight ? 'scroll' : 'initial',
                padding: `${spacing * 3}rem`,
                margin: `${spacing * 2}rem`,
                zIndex: zIndex.modal,
                background: this.props.backgroundColor
                  ? this.props.backgroundColor
                  : Colors.cc_white,
                borderLeft: this.props.borderLeftColor
                  ? `${spacing}rem solid ${this.props.borderLeftColor}`
                  : 'none',
                boxShadow: '0 4px 8px 0 rgba(27, 26, 26, 0.2)',
                border: '1px solid @brand-border'
              })}
            >
              {this.props.roundCloseButton && (
                <div
                  className={css({
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    right: 0,
                    height: `${spacing * 4}rem`,
                    width: `${spacing * 4}rem`,
                    backgroundColor: Colors.cc_secondary_100,
                    borderRadius: '50%',
                    cursor: 'pointer',
                    zIndex: zIndex.modal + 100,
                    '&:hover': {
                      backgroundColor: Colors.cc_secondary_100
                    }
                  })}
                  onClick={this.props.onCloseRequest}
                >
                  <MdIcons.MdClear size={spacing * remToPixels * 3} />
                </div>
              )}
              <div>
                {this.props.title && (
                  <>
                    <Text as="h2">{this.props.title}</Text>
                  </>
                )}

                {this.props.children}
              </div>
            </div>
          </div>
        )}
      </Backdrop>,
      document.body
    )
  }
}
