import * as React from 'react'

import { Colors, PhaseStateColors } from 'src/apps/main/helpers/Colors'
import { css, cx } from 'emotion'
import { fontStyles, spacing } from 'src/apps/main/helpers/CssProperties'

export type TextColors =
  | 'cc_primary_050'
  | 'cc_primary_075'
  | 'cc_primary_100'
  | 'cc_secondary_085'
  | 'cc_secondary_050'
  | 'cc_secondary_100'
  | 'cc_white'
  | 'cc_grey_100'
  | 'cc_grey_300'
  | 'cc_grey_500'
  | 'cc_grey_500'
  | 'cc_error_100'
  | 'cc_error_060'
  | 'cc_success_100'
  | 'cc_warning_060'
  | 'cc_warning_100'
  | 'cc_grey_700'
  | 'cc_black'
  | 'cc_tertiary_100'
  | PhaseStateColors

interface Props {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'p'
  fontStyle?: 'italic'
  fontWeight?: 'bold'
  pType?: 'pLarge' | 'pMedium' | 'pSmall' | 'mega' | 'price'
  marginBottom?: number
  paddingBottom?: number
  marginTop?: number
  textDecoration?: 'underline' | 'line-through'
  textTransform?: 'uppercase' | 'lowercase'
  display?: 'inline' | 'inline-block' | 'block'
  textAlign?: 'left' | 'center' | 'right' | 'justify'
  whiteSpace?: 'nowrap' | 'pre-line'
  overflowWrap?: 'break-word'
  truncate?: boolean
  color?: TextColors
  children: React.ReactNode
  className?: string
}

export function Text(props: Props) {
  // --- PROPS VALIDATION --- //
  if (props.as !== 'p' && props.pType)
    throw Error('You can only use props.pType with as === "p"')

  if (props.truncate && props.display !== Text.defaultProps.display)
    throw Error('props.truncate can not be used with props.display')

  // --- CONSTS --- //
  const Element = props.as ? props.as : 'span'

  const textStyles = css`
    color: ${Colors[props.color!]};
    display: ${props.as ? props.display : 'inline'};
    font-size: ${!props.as && fontStyles.p.fontSize};
    font-style: ${props.fontStyle};
    font-weight: ${props.fontWeight};
    text-decoration: ${props.textDecoration};
    text-transform: ${props.textTransform};
    text-align: ${props.textAlign};
    white-space: ${props.truncate ? 'nowrap' : props.whiteSpace};
    overflow: ${props.truncate && 'hidden'};
    text-overflow: ${props.truncate && 'ellipsis'};
    overflow-wrap: ${props.overflowWrap};
    font-family: ${props.fontWeight === 'bold'
      ? "'Noto IKEA Latin Bold', Arial, serif"
      : "'Noto IKEA Latin', Arial, serif"};
    margin-bottom: ${props.marginBottom ? spacing * props.marginBottom : 0}rem;
    margin-top: ${props.marginTop ? spacing * props.marginTop : 0}rem;
  `
  const pStyle = css`
    font-size: ${fontStyles.p.fontSize};
    line-height: ${fontStyles.p.lineHeight};
  `
  const pSmall = css`
    font-size: ${fontStyles.pSmall.fontSize};
    letter-spacing: 0.02rem;
    font-weight: ${fontStyles.pSmall.fontWeight};
    line-height: ${fontStyles.pSmall.lineHeight};
  `
  const pLarge = css`
    font-size: ${fontStyles.pLarge.fontSize};
    font-weight: ${fontStyles.pLarge.fontWeight};
    line-height: ${fontStyles.pLarge.lineHeight};
  `
  const pMedium = css`
    font-size: ${fontStyles.h3.fontSize};
    font-weight: ${fontStyles.h3.fontWeight};
    line-height: ${fontStyles.h3.lineHeight};
  `
  const Mega = css`
    font-size: ${fontStyles.mega.fontSize};
    font-weight: ${fontStyles.mega.fontWeight};
    line-height: ${fontStyles.mega.lineHeight};
  `
  const price = css`
    font-size: ${fontStyles.price.fontSize};
    font-weight: ${fontStyles.price.fontWeight};
    line-height: ${fontStyles.price.lineHeight};
  `
  const H1Style = css`
    font-size: ${fontStyles.h1.fontSize};
    padding-bottom: ${props.paddingBottom !== undefined
      ? props.paddingBottom
      : spacing * 2}rem;
    font-weight: ${fontStyles.h1.fontWeight};
    line-height: ${fontStyles.h1.lineHeight};
  `
  const H2Style = css`
    font-size: ${fontStyles.h2.fontSize};
    padding-bottom: ${props.paddingBottom !== undefined
      ? props.paddingBottom
      : spacing}rem;
    font-weight: ${fontStyles.h2.fontWeight};
    line-height: ${fontStyles.h2.lineHeight};
  `
  const H3Style = css`
    font-size: ${fontStyles.h3.fontSize};
    text-transform: uppercase !important;
    font-weight: ${fontStyles.h3.fontWeight};
    line-height: ${fontStyles.h3.lineHeight};
  `
  const H4Style = css`
    font-size: ${fontStyles.h4.fontSize};
    font-weight: ${fontStyles.h4.fontWeight};
    line-height: ${fontStyles.h4.lineHeight};
  `
  const SpanTextStyle = css`
    font-size: ${fontStyles.p.fontSize};
    font-weight: ${fontStyles.p.fontWeight};
    line-height: ${fontStyles.p.lineHeight};
  `
  return (
    <Element
      className={cx(
        props.as === 'h1'
          ? H1Style
          : props.as === 'h2'
          ? H2Style
          : props.as === 'h3'
          ? H3Style
          : props.as === 'h4'
          ? H4Style
          : props.as === 'p'
          ? pStyle
          : SpanTextStyle,
        props.pType === 'pSmall'
          ? pSmall
          : props.pType === 'pLarge'
          ? pLarge
          : props.pType === 'pMedium'
          ? pMedium
          : props.pType === 'mega'
          ? Mega
          : props.pType === 'price'
          ? price
          : undefined,
        textStyles,
        props.className
      )}
    >
      {props.children}
    </Element>
  )
}

Text.defaultProps = {
  color: Colors.cc_primary_100,
  fontStyle: 'normal',
  fontWeigth: 'normal',
  textDecoration: 'none',
  textTransform: 'none',
  display: 'block',
  textAlign: 'start',
  whiteSpace: 'normal',
  overflowWrap: 'normal',
  truncate: false
}
