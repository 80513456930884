import * as React from 'react'

import { MaterialIcon, MaterialIcons } from '../Base/MaterialIcon'
import { css, cx } from 'emotion'
import {
  fontStyles,
  remToPixels,
  spacing,
  transitions
} from '../../helpers/CssProperties'

import { Tooltip } from '../Base/Tooltip'

export interface BaseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Default is 'button */
  type?: 'submit'
  children?: string
  mdIcon?: MaterialIcons
  className?: string
  tooltip?: string
  testId?: string
}

export function BaseButton({
  className,
  type,
  children,
  mdIcon,
  testId,
  tooltip,
  ...baseProps
}: BaseButtonProps) {
  const buttonElement = () => (
    <button
      {...baseProps}
      data-testid={testId}
      type={type || 'button'}
      className={cx([ButtonStyle, className])}
    >
      {mdIcon && (
        <MaterialIcon
          icon={mdIcon}
          size={spacing * 2 * remToPixels}
          color="currentColor"
          className={css`
            margin-right: ${children && 0.3}rem;
          `}
        />
      )}
      {children}
    </button>
  )

  return (
    <>
      {tooltip ? (
        <Tooltip text={tooltip}>
          <div className={TooltipWrapperStyle} tabIndex={0}>
            {buttonElement()}
          </div>
        </Tooltip>
      ) : (
        buttonElement()
      )}
    </>
  )
}

// This wrapper is required to be able to trigger the onMouseLeave event on a disabled button
const TooltipWrapperStyle = css`
  display: inline-block;

  button {
    pointer-events: none;
  }
`

const ButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  font-size: ${fontStyles.p.fontSize};
  line-height: ${fontStyles.p.lineHeight};
  padding: ${spacing}rem;
  border: none;
  border-radius: ${spacing * 0.5}rem;
  outline: none;
  transition: ${transitions('opacity', 'backgroundChange')};
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
  &:hover:enabled {
    opacity: 0.75;
  }
`
