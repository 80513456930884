import * as React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { zIndex } from 'src/apps/main/helpers/CssProperties'

interface BackdropProps {
  open: boolean
  topSpacing?: number
  isFlexStart?: boolean
  onBackdropClick?: () => void
  children?: React.ReactNode
}

interface BackdropState {
  hasOpened: boolean
}

export class Backdrop extends React.Component<BackdropProps, BackdropState> {
  private backdropElement: HTMLDivElement | undefined

  render() {
    return (
      <TransitionGroup>
        {this.props.open && (
          <CSSTransition classNames="slide-down" timeout={150}>
            <div
              ref={ref => {
                this.backdropElement = ref!
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: this.props.isFlexStart ? 'flex-start' : 'center',
                visibility: this.props.open ? 'visible' : 'hidden',
                position: 'fixed',
                top: this.props.topSpacing || 0,
                left: 0,
                right: 0,
                bottom: '-10px',
                backgroundColor: 'rgba(32, 32, 32, 0.8)',
                overflow: 'auto',
                zIndex: zIndex.modalBackdrop
              }}
              onClick={e => {
                e.stopPropagation()
                if (e.target === this.backdropElement) {
                  this.props.onBackdropClick!()
                }
              }}
            >
              {this.props.children}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    )
  }
}
